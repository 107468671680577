import axios from "axios";
const instance = axios.create({
  baseURL: process.env.VUE_APP_IDENTITY_BASE_URL,
});

instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.method = "post";

instance.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
