<template>
  <div v-show="!$route.meta.hideBottomNavigation" class="bottom-fixed-bar" ref="bottomBar">
    <slot></slot>
    <div class="bottom-navigation">
      <a class="nav-item" @click="showMenu">
        <div>
          <svg
            class="menu-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M3 5.83301H18"
              stroke="#FCF2F2"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M3 10H18"
              stroke="#FCF2F2"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M3 14.167H18"
              stroke="#FCF2F2"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          <span class="item-name">{{ $t("menu") }}</span>
        </div>
      </a>

      <router-link to="/search" class="nav-item">
        <div>
          <div class="icon">
            <svg
              class="menu-icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M10.0834 17.5003C14.4556 17.5003 18 13.9559 18 9.58366C18 5.2114 14.4556 1.66699 10.0834 1.66699C5.7111 1.66699 2.16669 5.2114 2.16669 9.58366C2.16669 13.9559 5.7111 17.5003 10.0834 17.5003Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.8334 18.3337L17.1667 16.667"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="item-name">{{ $t("search") }}</span>
        </div>
      </router-link>

      <router-link to="/bet-slip" class="nav-item">
        <div>
          <div class="icon">
            <svg
              class="menu-icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M9.58527 16.5584H14.9769C18.0603 16.5584 18.8269 15.7917 18.8269 12.7084C17.7603 12.7084 16.9019 11.8417 16.9019 10.7834C16.9019 9.71673 17.7603 8.85007 18.8269 8.85007V8.0834C18.8269 5.00007 18.0603 4.2334 14.9769 4.2334H9.66027V9.89173"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66132 14.058V16.558H7.35299C6.11966 16.558 5.39466 15.7163 4.59466 13.783L4.44466 13.408C5.45299 13.008 5.94466 11.8413 5.51966 10.833C5.11132 9.82467 3.95299 9.34134 2.93632 9.75801L2.79466 9.39967C1.59466 6.46634 2.01132 5.44134 4.94466 4.23301L7.14466 3.33301L9.66132 9.43301V11.558"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.30182 16.5586H7.16016"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span v-show="betslip_count > 0" class="custom-badge">
              {{ betslip_count }}
            </span>
          </div>
          <span class="item-name">{{ $t("Bet slips") }}</span>
        </div>
      </router-link>

      <router-link to="/my-bets" class="nav-item">
        <div>
          <div class="icon">
            <svg
              class="menu-icon-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M18 5.83366V14.167C18 16.667 16.75 18.3337 13.8333 18.3337H7.16667C4.25 18.3337 3 16.667 3 14.167V5.83366C3 3.33366 4.25 1.66699 7.16667 1.66699H13.8333C16.75 1.66699 18 3.33366 18 5.83366Z"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5833 3.75V5.41667C12.5833 6.33333 13.3333 7.08333 14.25 7.08333H15.9166"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.16669 10.833H10.5"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.16669 14.167H13.8334"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span v-show="normalBetsCount > 0" class="custom-badge">
              {{ normalBetsCount }}
            </span>
          </div>
          <span class="item-name"> {{ $t("myBets") }}</span>
        </div>
      </router-link>

      <router-link v-show="isLoggedIn" to="/my-account" class="nav-item">
        <div>
          <svg
            class="menu-icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M10.5 10.0003C12.8012 10.0003 14.6666 8.13485 14.6666 5.83366C14.6666 3.53247 12.8012 1.66699 10.5 1.66699C8.19879 1.66699 6.33331 3.53247 6.33331 5.83366C6.33331 8.13485 8.19879 10.0003 10.5 10.0003Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.6583 18.3333C17.6583 15.1083 14.45 12.5 10.5 12.5C6.55001 12.5 3.34167 15.1083 3.34167 18.3333"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="item-name">{{ $t("account") }}</span>
        </div>
      </router-link>

      <router-link v-show="!isLoggedIn" to="/login" class="nav-item">
        <div>
          <img src="/img/BottomNav/user.svg" class="menu-icon-svg" />
          <span class="item-name">{{ $t("login") }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
let observer = null;
export default {
  name: "BottomNavigationMenu",
  props: ["isLoggedIn", "normalBetsCount", "betslip_count", "liveGames"],
  mounted() {
    this.getBottomBarHeight();
    observer = new ResizeObserver(this.getBottomBarHeight).observe(
      this.$refs.bottomBar
    );
  },
  beforeDestroy() {
    // console.log("unmounted");
    document.documentElement.style.setProperty("--bottom-bar-height", "0px");
    if (observer) {
      observer.disconnect();
    }
  },
  methods: {
    getBottomBarHeight() {
      const height = this.$refs.bottomBar.clientHeight;
      document.documentElement.style.setProperty(
        "--bottom-bar-height",
        `${height + 20}px`
      );
    },
  },
};
</script>

<style scoped>
.bottom-fixed-bar {
  /* background: red; */
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.bottom-navigation {
  background-color: #0e0d0d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1rem; */
  /* padding: 10px; */
  border-top: 1px solid var(--primary-bg-light);
}

.bottom-navigation.sticky {
  transform: translateY(0);
}

.custom-badge {
  position: absolute;
  right: -15px;
  bottom: -0px;
  padding: 10px;
  font-size: 12px;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 12px;
  border-radius: 9999px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item {
  display: block;
  /* outline: 1px solid red; */
  width: 100%;
  height: 64px;
}

.nav-item > div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100%;
  span {
    color: #fcf2f2;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
    margin-top: 4px;
    color: rgba(255, 255, 255, 0.4);
  }
}

.nav-item .icon {
  position: relative;
}

.menu-icon-svg {
  width: 20px;
  height: 20px;
}

/* .nav-item > i {
} */
/* 
.nav-item img {
}

.nav-item > span {
} */
</style>
