<template>
  <div class="betslips">
    <div style="margin-bottom: 4px">
      <p>{{ pick.competitor_1 }} - {{ pick.competitor_2 }}</p>
      <span
        v-show="!isDeactivated"
        class="delete"
        @click="removePick(pick.match_id)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <path
            d="M1.66663 1.66699L8.33329 8.33366M1.66663 8.33366L8.33329 1.66699"
            stroke="#CC1717"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>

    <span class="bold">
      <strong>{{ pick.market_name || "--" }}</strong></span
    >
    <div>
      <p>
        <span>Your pick: {{ " " }}</span>
        <strong>{{ pick.outcome_name }}</strong>
      </p>
      <span class="bold">{{ pick.odds }}</span>
    </div>

    <div class="error" v-show="oddsDeactivated.length > 0">
      <span>{{ oddsDeactivated }}</span>
    </div>

    <div class="warning" v-show="oddsDown.length > 0">
      <span>{{ oddsDown }}</span>
    </div>

    <div class="info" v-show="oddsUp.length > 0">
      <span>{{ oddsUp }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pick",
  data() {
    return {
      oddsDeactivated: "",
      oddsDown: "",
      oddsUp: "",
      currentOdd: 0,
      isDeactivated: false,
    };
  },
  methods: {},
  computed: {
    pick: function () {
      // console.log(this.selection, "this.selection");
      return this.selection;
    },
  },
  props: {
    selection: {
      required: true,
      default: "",
    },
  },
  mounted() {
    this.currentOdd = this.pick.odds;

    this.id = this.getOddID(
      this.pick.match_id,
      this.pick.market_id,
      this.pick.outcome_id,
      this.pick.specifier,
      "betslip"
    );

    var vm = this;

    // subscribe to UI event to update odds incase of odds change, this is done through event bus
    // we only expect to receive odds updates on this topic
    this.EventBus.$on("odds:" + vm.id, function (payload) {
      vm.oddsDeactivated = "";
      vm.oddsUp = "";
      vm.oddsDown = "";

      if (parseInt(payload.status) !== 0 || parseInt(payload.active) !== 1) {
        vm.oddsDeactivated = "Selection deactivated";
        vm.EventBus.$emit("event:betslip:odds-deactivated");
        vm.isDeactivated = true;
      } else {
        if (parseFloat(vm.currentOdd) > parseFloat(payload.odds)) {
          vm.oddsUp = `Odds have gone up from ${vm.currentOdd} to ${payload.odds}`;
          vm.EventBus.$emit("event:betslip:odds-changed");
        } else if (parseFloat(vm.currentOdd) < parseFloat(payload.odds)) {
          vm.oddsDown = `Odds have gone down from ${vm.currentOdd} to ${payload.odds}`;
          vm.EventBus.$emit("event:betslip:odds-changed");
        }
      }
    });

    // when this action is triggered, check if this component is deactivated and click removePick
    this.EventBus.$on("event:betslip:remove-deactivated", function () {
      if (vm.isDeactivated) {
        vm.removePick(vm.pick.match_id);
      }
    });
  },
};
</script>

<style scoped>
.betslips {
  border-bottom: 1px solid var(--border);
  padding: 8px;
  gap: 4px;
}

.betslips > div {
  justify-content: space-between;
  display: flex;
  align-items: center;
  align-items: flex-start;

  p {
    margin: 0;
    color: var(--text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
    width: 70%;
  }
}

span {
  color: var(--sub-text-color);
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}

.bold {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  max-width: 90%;

  display: block;
  text-wrap: wrap;
  word-break: break-all;

  /* white-space: nowrap; */
  /* overflow: hidden; */
  display: inline-block;
  text-overflow: ellipsis;
}

.light {
  color: var(--sub-text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}

.error {
  background-color: red;
  padding: 4px;
  border-radius: 3px;
  margin-bottom: 3px;
  margin-top: 3px;

  span {
    color: var(--text-color);
    font-size: 10px;
    font-weight: lighter;
  }
}

.info {
  background-color: green;
  padding: 4px;
  border-radius: 3px;
  margin-bottom: 3px;
  margin-top: 3px;

  span {
    /* color: #fff; */
    color: var(--text-color);
    font-size: 10px;
    font-weight: lighter;
  }
}

.warning {
  background-color: orange;
  padding: 4px;
  border-radius: 3px;
  margin-bottom: 3px;
  margin-top: 3px;

  span {
    /* color: #fff; */
    color: var(--text-color);
    /* color: red; */
    font-size: 10px;
    font-weight: lighter;
  }
}
</style>
