<template>
  <div class="body">
    <div style="min-height: 90vh" id="app">
      <section
        ref="topHeader"
        style="top: 0; width: 100%; max-width: 768px; z-index: 1000"
        class="top-header"
        v-bind:class="canStick()"
        v-if="current_page != 'print-game'"
      >
        <div
          class="d-flex m-0 align-items-center header"
          style="background-color: var(--background-color)"
          v-if="!$route.meta.hideMenu"
        >
          <div class="logo">
            <router-link to="/">
              <ChopbetLogo />
            </router-link>
            <img
              style="height: 16px; width: 16px"
              src="/img/ivory_coast.svg"
              class="top-header-logo img-fluid"
              alt="ivory coast flag"
            />
          </div>
          <div class="p-0 login">
            <div v-if="profile" class="nav_right">
              <router-link style="padding: 3px; border-radius: 5px" to="/inbox">
                <CommentIcon />
              </router-link>
              <router-link class="wallet-balance" to="/deposit-funds">
                <span>{{ bal }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1.83337 7.99967C1.83337 4.60248 4.60285 1.83301 8.00004 1.83301C11.3972 1.83301 14.1667 4.60248 14.1667 7.99967C14.1667 11.3969 11.3972 14.1663 8.00004 14.1663C4.60285 14.1663 1.83337 11.3969 1.83337 7.99967ZM9.00004 10.6663V8.99967H10.6667C11.2162 8.99967 11.6667 8.54915 11.6667 7.99967C11.6667 7.4502 11.2162 6.99967 10.6667 6.99967H9.00004V5.33301C9.00004 4.78353 8.54952 4.33301 8.00004 4.33301C7.45056 4.33301 7.00004 4.78353 7.00004 5.33301V6.99967H5.33337C4.7839 6.99967 4.33337 7.4502 4.33337 7.99967C4.33337 8.54915 4.7839 8.99967 5.33337 8.99967H7.00004V10.6663C7.00004 11.2158 7.45056 11.6663 8.00004 11.6663C8.54952 11.6663 9.00004 11.2158 9.00004 10.6663Z"
                    fill="var(--deposit-fill)"
                    stroke="var(--deposit-stroke)"
                  />
                </svg>
              </router-link>
            </div>

            <div v-else class="d-flex align-items-center text-center d-none">
              <LanguageToggle />
              <router-link
                to="/login"
                class="header-login-button"
                style="color: var(--text-color) !important"
              >
                {{ $t("login") }}
              </router-link>
              <router-link
                to="/join"
                class="header-join-button"
                style="color: #fff !important"
              >
                {{ $t("join") }}
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <section
        style="position: relative; z-index: 500"
        class="feature-menu"
        v-if="!$route.meta.hideGamesMenu"
      >
        <div class="icons-wrapper top-menu-items">
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('sports')"
            @click="setActive('sports')"
          >
            <router-link to="/">
              <div class="icon-container">
                <!-- <img src="/img/TopNav/football.svg" class="menu-icon-svg" /> -->
                <SportIcon />
              </div>
              <span class="text-dark txt"> {{ this.$t("sports") }} </span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('live')"
            @click="setActive('live')"
          >
            <router-link to="/live">
              <div class="icon-container">
                <div class="menu-icon-svg">
                  <img src="/img/Live.gif" class="live-icon" alt="Live" />
                </div>
              </div>
              <span class="text-dark txt"> {{ $t("live") }}</span>
            </router-link>
          </div>
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('casino')"
            @click="setActive('casino')"
            v-if="profile"
          >
            <router-link to="/casino">
              <div class="icon-container">
                <!-- <img src="/img/TopNav/Casino.svg" class="menu-icon-svg" /> -->
                <CasinoIcon />
              </div>
              <span class="text-dark txt">{{ $t("casino") }}</span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('leaderboard')"
            @click="setActive('leaderboard')"
          >
            <router-link to="/leaderboard">
              <div class="icon-container">
                <!-- <Ranking /> -->
                <LeaderboardIcon />
              </div>
              <span class="text-dark txt">{{ $t("leaderboards") }}</span>
            </router-link>
          </div>
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('gifts')"
            @click="setActive('gifts')"
          >
            <router-link to="/gifts">
              <div class="icon-container">
                <!-- <img src="/img/TopNav/gift.svg" class="menu-icon-svg" /> -->
                <PromosIcon />
              </div>
              <span class="text-dark txt">{{ $t("promos") }} </span>
            </router-link>
          </div>

          <!-- <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('virtuals')"
            @click="setActive('virtuals')"
          >
            <router-link to="/virtuals">
              <div class="icon-container">
                <div class="menu-icon-svg">
                  <VirtualIcon />
                </div>
              </div>
              <span class="text-dark txt">{{ $t("virtual") }} </span>
            </router-link>
          </div> -->
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('jackpot')"
            @click="setActive('jackpot')"
          >
            <router-link to="/jackpot">
              <div class="icon-container">
                <div class="menu-icon-svg">
                  <JackpotIcon />
                </div>
              </div>
              <span class="text-dark txt">{{ $t("jackpots") }} </span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            :class="isActive('chopmaster')"
          >
            <a
              href="https://chopmaster.chopbet.ci/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="icon-container">
                <ChopmasterIcon />
              </div>
              <span class="text-dark txt">Chopmaster</span>
            </a>
          </div>
        </div>
      </section>

      <!--A-Z Menu-->
      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999">
        <!-- Modal content -->
        <div
          style="background-color: var(--background-color)"
          class="faso-menu-modal-content"
        >
          <section class="menu-bg">
            <div class="side-menu-header" @click="goTo('')">
              <span class="cancel">
                <CancelIcon />
              </span>
              <div class="logo">
                <router-link to="/">
                  <ChopbetLogo />
                </router-link>
                <img
                  style="height: 14px; width: 14px"
                  src="/img/ivory_coast.svg"
                  class="top-header-logo img-fluid"
                  alt="ivory coast flag"
                />
              </div>
              <div class="text-right"></div>
            </div>
            <div
              v-for="(menuItem, index) in menuItems"
              :key="index"
              class="d-flex justify-content-between align-items-center flatlink-div account"
            >
              <router-link :to="menuItem.link" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img :src="menuItem.icon" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>{{ menuItem.label }}</strong>
                  </span>
                </a>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.94 13.2807L10.2867 8.93404C10.8 8.4207 10.8 7.5807 10.2867 7.06737L5.94 2.7207"
                    stroke="#BAB7B7"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
            <div class="header_text">
              <span class="text-dark">{{ $t("playerAccount") }}</span>
            </div>
            <div
              v-for="(accountItem, index) in accountItems"
              :key="index"
              class="flatlink-div"
            >
              <router-link :to="accountItem.link" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img :src="accountItem.icon" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>{{ accountItem.label }}</strong>
                  </span>
                </a>

                <!-- { icon } -->

                <DarkMode v-if="accountItem.icon === '/menu/display.svg'" />
                <!-- <svg
                  v-if="accountItem.icon === '/menu/display.svg'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.94 13.2807L10.2867 8.93404C10.8 8.4207 10.8 7.5807 10.2867 7.06737L5.94 2.7207"
                    stroke="#BAB7B7"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> -->

                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.94 13.2807L10.2867 8.93404C10.8 8.4207 10.8 7.5807 10.2867 7.06737L5.94 2.7207"
                    stroke="#BAB7B7"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
            <ThemeSwitch />
            <div class="header_text">
              <span class="text-dark">{{ $t("help") }}</span>
            </div>
            <div
              v-for="(accountItem, index) in menuItems2"
              :key="index"
              class="flatlink-div"
            >
              <router-link :to="accountItem.link" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img :src="accountItem.icon" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>{{ accountItem.label }}</strong>
                  </span>
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.94 13.2807L10.2867 8.93404C10.8 8.4207 10.8 7.5807 10.2867 7.06737L5.94 2.7207"
                    stroke="#BAB7B7"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
            <div style="border-bottom: solid var(--border) 1px"></div>
            <div
              class="d-flex justify-content-left flatlink-div"
              v-if="profile"
            >
              <a @click="logout" class="flat-link">
                <span style="color: #cc1717">{{ $t("logout") }}</span>
              </a>
            </div>
          </section>
        </div>
      </div>
      <!--End A-Z Menu-->
      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>
      <router-view></router-view>
      <div v-if="!$route.meta.hideBottomNavigation">
        <BottomNavigation></BottomNavigation>
      </div>
      <div
        class="depo-widget d-none"
        id="dpo-widg"
        v-show="current_page != 'deposit'"
      >
        <span id="dpo-close" class="text-dark" @click="closeWidget()"
          ><i class="bi bi-x-lg"></i
        ></span>
        <router-link
          to="/deposit"
          class="deposit-link"
          v-show="current_page != 'deposit'"
        >
          <img src="/img/depo.webp" class="depo-widg" />
        </router-link>
      </div>
    </div>
    <div v-if="showFooter">
      <Footer />
    </div>
  </div>
</template>

<!-- <style scoped>

</style> -->

<script>
import mqtt from "mqtt";
import Footer from "./components/Footer.vue";
import axios from "@/services/api";
import store from "./store/store";
import LanguageToggle from "./components/ui/LanguageToggle.vue";
// import LiveIcon from "./components/icons/LiveIcon.vue";
// import VirtualIcon from "./components/icons/VirtualIcon.vue";
// import JackpotIcon from "./components/icons/JackpotIcon.vue";
import ChopmasterIcon from "./components/icons/sports/ChopmasterIcon.vue";
import JackpotIcon from "./components/icons/sports/JackpotIcon.vue";
import PromosIcon from "./components/icons/sports/PromosIcon.vue";
import CasinoIcon from "./components/icons/sports/CasinoIcon.vue";
import SportIcon from "./components/icons/sports/SportIcon.vue";
import LeaderboardIcon from "./components/icons/sports/LeaderboardIcon.vue";

// import Ranking from "./components/icons/Ranking.vue";
import DarkMode from "./components/ui/DarkMode.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
import walletserve from "./services/walletserve";
import ThemeSwitch from "./components/ui/ThemeSwitch.vue";
import ChopbetLogo from "./components/icons/ChopbetLogo.vue";
import CancelIcon from "./components/icons/CancelIcon.vue";
import CommentIcon from "./components/icons/CommentIcon.vue";

export default {
  store,
  name: "app",
  data: function () {
    return {
      currency: process.env.VUE_APP_CURRENCY,
      current_menu: "",
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: "",
      message: this.$t("pleaseEnterAmountToDeposit"),
      aviatorGame: [
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SPRIBE),
          gameID: "aviator",
          image: "/img/TopNav/ChopmasterIcon.svg",
          gameName: this.$t("Chopmaster"),
        },
      ],
      isWebView: false,
      referralCode: null,
      homePageGame: [
        {
          providerID: parseInt(process.env.VUE_APP_PROVIDER_ID_SMART),
          gameID: "JetX",
          gameName: this.$t("leaderboard"),
          image: "/img/TopNav/ranking.svg",
          crashGameUrl: "jetx",
        },
      ],
      accountItems: [
        {
          label: this.$t("betHistory"),
          link: "/transactions",
          icon: "/menu/time.svg",
        },
        {
          label: this.$t("myAccount"),
          link: "/my-account",
          icon: "/menu/user-square.svg",
        },
        { label: this.$t("inbox"), link: "/inbox", icon: "/menu/message.svg" },
        {
          label: this.$t("language"),
          link: "/language",
          icon: "/menu/global.svg",
        },
        // {
        //   label: this.$t("display"),
        //   link: "/my-bets",
        //   icon: "/menu/display.svg",
        // },
      ],
      // menuItems: [
      //   { label: this.$t("sports"), link: "/", icon: "/menu/soccer-red.svg" },
      //   { label: this.$t("liveGames"), link: "/live", icon: "/menu/live.svg" },
      //   {
      //     label: this.$t("virtuals"),
      //     link: "/virtuals",
      //     icon: "/menu/game.svg",
      //   },
      //   { label: this.$t("casino"), link: "/casino", icon: "/menu/casino.svg" },
      //   {
      //     label: this.$t("jackpots"),
      //     link: "/jackpot",
      //     icon: "/menu/crown.svg",
      //   },
      //   {
      //     label: this.$t("leaderboards"),
      //     link: "/leaderboard",
      //     icon: "/menu/ranking.svg",
      //   },
      // ],
      menuItems2: [
        {
          link: "/customer-support",
          icon: "/menu/support.svg",
          translationKey: "customerSupport",
          label: `${this.$t("customerSupport")}`,
        },
        {
          link: "/responsible-gaming",
          icon: "/menu/18-plus.svg",
          translationKey: "responsibleGaming",
          label: `${this.$t("responsibleGaming")}`,
        },
        {
          link: "/faq",
          icon: "/menu/question.svg",
          translationKey: "frequentlyAskedQuestions",
          label: `${this.$t("frequentlyAskedQuestions")}`,
        },
        {
          link: "/terms",
          icon: "/menu/terms-condition.svg",
          translationKey: "termsAndConditions",
          label: `${this.$t("termsAndConditions")}`,
        },
        {
          link: "/privacy",
          icon: "/menu/privacy.png",
          translationKey: "privacyPolicy",
          label: `${this.$t("privacyPolicy")}`,
        },
        {
          link: "/rules",
          icon: "/menu/betting-rule.svg",
          translationKey: "bettingRules",
          label: `${this.$t("bettingRules")}`,
        },
      ],
    };
  },
  components: {
    LeaderboardIcon,
    SportIcon,
    CasinoIcon,
    PromosIcon,
    JackpotIcon,
    DarkMode,
    // LiveIcon,
    Footer,
    LanguageToggle,
    ChopmasterIcon,
    CommentIcon,
    // VirtualIcon,
    // Ranking,
    BottomNavigation,
    ThemeSwitch,
    ChopbetLogo,
    CancelIcon,
  },
  mounted: function () {
    // console.log("App vue mounted ---------");
    // this.reloadProfile();
    var vm = this;
    this.checkIfWebView();
    this.initMenuModal();
    this.getSports();
    this.EventBus.$on("deposit:popup", function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }
    });

    this.getTopHeaderHeight();
    this.EventBus.$on("init:mqtt", function () {
      // console.log("Wants Inititalize MQTT");

      if (vm.mqttClient !== false) {
        vm.mqttClient.end();
        vm.mqttClient = false;
      }
      vm.myProfile = vm.getProfile();
      vm.initMqtt();
    });
    vm.initMqtt();
    var params = this.getURLParameters();
    var utm_source = params.utm_source ? params.utm_source : "";
    var utm_medium = params.utm_medium ? params.utm_medium : "";
    var utm_campaign = params.utm_campaign ? params.utm_campaign : "";
    var referral_code = params.p ? params.p : "";
    var referrer = document.referrer;
    var btag = params.btag ? params.btag : "";
    // console.log("GOT referrer " + referrer);

    if (this.profile) {
      this.fetchBalance();
    }
    if (btag.length > 0) {
      this.setValue("btag", btag);
    }

    if (utm_source.length > 0) {
      this.setValue("utm_source", utm_source);
    }

    if (utm_medium.length > 0) {
      this.setValue("utm_medium", utm_medium);
    }

    if (referral_code.length > 0) {
      this.setValue("referral_code", referral_code);
    }

    if (utm_campaign.length > 0) {
      this.setValue("utm_campaign", utm_campaign);
    }
    this.setValue("referrer", referrer);

    if (this.$refs.topHeader) {
      window.addEventListener("resize", this.getTopHeaderHeight);
    }
  },

  unmounted: function () {
    window.removeEventListener("resize", this.getTopHeaderHeight);
  },

  computed: {
    menuItems() {
      const items = [
        { label: this.$t("sports"), link: "/", icon: "/menu/soccer-red.svg" },
        { label: this.$t("liveGames"), link: "/live", icon: "/menu/live.svg" },
        // {
        //   label: this.$t("virtuals"),
        //   link: "/virtuals",
        //   icon: "/menu/game.svg",
        // },
        {
          label: this.$t("jackpots"),
          link: "/jackpot",
          icon: "/menu/crown.svg",
        },
        {
          label: this.$t("leaderboards"),
          link: "/leaderboard",
          icon: "/menu/ranking.svg",
        },
      ];
      if (this.profile) {
        items.push({
          label: this.$t("casino"),
          link: "/casino",
          icon: "/menu/casino.svg",
        });
      }
      return items;
    },
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return "";
      } else {
        return "d-none";
      }
    },
    bal: function () {
      return this.formatCurrency(this.$store.state.walletBalance);
    },
    full_name: function () {
      if (this.profile === undefined) {
        return "";
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {
        return ms;
      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + " " + mn + " " + ln + " - " + ms;
    },
    profile: function () {
      return this.myProfile;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
    homePageFontColor: function () {
      if (this.current_page === "home") {
        return "yellow-txt";
      }

      return "";
    },
    livePageFontColor: function () {
      if (this.current_page === "live") {
        return "yellow-txt";
      }

      return "";
    },
    historyPageFontColor: function () {
      if (this.current_page === "history") {
        return "yellow-txt";
      }

      return "";
    },
    betslip: function () {
      return this.$store.state.betslip;
    },
    activeBets: function () {
      var p = this.getProfile();

      if (!p) {
        return 0;
      }

      return p.b;
    },
    betslip_count: function () {
      if (!this.betslip.total || this.betslip.total == "") {
        return 0;
      }

      return parseInt(this.betslip.total);
    },
  },
  created() {
    this.$store.dispatch("fetchReferralCode");
    const savedTheme = localStorage.getItem("theme") || this.getSystemTheme();
    this.$store.dispatch("setTheme", savedTheme);
  },
  methods: {
    getSystemTheme() {
      // Detect system theme preference (if available)
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    },
    checkIfWebView() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isWebView =
        /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
        /\bwv\b/.test(userAgent) ||
        /Android.*Version\/[\d.]+.*(?:Chrome\/[\d.]+ Mobile Safari\/[\d.]+|wv)/i.test(
          userAgent
        );
      this.isWebView = isWebView;
      // if (isWebView) {
      //   console.log("User is using a WebView.");
      // } else {
      //   console.log("User is using a standard browser.");
      // }
    },
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },
    getReferrer() {
      return document.referrer || "No referrer found";
    },
    canStick: function () {
      if (
        this.current_page == "casinogames" ||
        this.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "header-stick";
      }
    },
    canFeatureStick: function () {
      if (
        this.current_page == "match" ||
        this.current_page == "casinogames" ||
        this.current_page == "casino" ||
        this.current_page == "casino-live" ||
        this.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "feature-stick";
      }
    },
    isActive: function (page) {
      if (this.$route.name === page) {
        this.setActive(page);
      }
      return this.$route.name === page ? "active" : "";
    },
    fetchBalance: async function () {
      const profile = this.getProfile();
      // console.log(profile, "profile------");
      if (!profile) {
        return;
      }
      const path = `${process.env.VUE_APP_BASE_WALLET_URL}/balance`;
      try {
        const response = await walletserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        const updatedProfileData = response.data;
        this.EventBus.$emit("init:mqtt");
        profile.b1 = updatedProfileData.b1;
        this.$store.dispatch("setProfileBalance", updatedProfileData.b1);
        this.bal = this.formatCurrency(updatedProfileData.b1);
        // this.setProfile(profile);
      } catch (err) {
        console.log("Error Caught:", err);
        if (err.response) {
          var message =
            err.response.data.error_message || "Something went wrong";
          if (parseInt(err.response.status) === 428) {
            this.setError(message);
            return;
          }
          if (parseInt(err.response.status) === 401) {
            this.setError(message);
            this.logout();
            return;
          } else {
            this.setError(message);
          }
        }
      }
    },
    setActive: function (menu) {
      this.$store.state.current_page = menu;
      this.current_menu = menu;
    },
    livescore: function () {},

    initMqtt: function () {
      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;
      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var client = mqtt.connect(endpoint, options);

      client.on("connect", function () {
        // console.log("---- Web socket connected here ----");

        // subscribe to me topic
        if (vm.myProfile) {
          var profileTopic = "topic/profile/" + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();
          client.subscribe(profileTopic, function (err) {
            if (!err) {
              // console.log(
              //   "------subscribed to topic profile------" + profileTopic
              // );
            }
          });
        }
      });

      client.on("message", function (topic, msg) {
        var payload = JSON.parse(msg.toString()); // converts binary message to text then parses it as JSON
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;

      this.$store.dispatch("setWebSocketState", client);
      // this.$store.dispatch("setWebSocketState", client);
    },

    getMarket: function (sport_id) {
      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {
      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));
      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      // console.log(this.$store.state.sport_id);
      this.$router.push({ name: "sport", params: {} });
    },

    initMenuModal: function () {
      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = "";
      };

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("menu-modal")) {
          modal.style.display = "none";
        }
      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },

    initDeposit: function () {
      this.dismiss();
      this.$router.push({ name: "deposit" });
    },
    dismiss: function () {
      // console.log("DISMISS MENU");
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "sport-" + index + "-");
    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },

    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
      this.deposit();
    },

    deposit: function () {
      this.reset();
      var p = this.getProfile();

      if (!p) {
        this.setError(`${this.$t("pleaseLoginProceed")}`);
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 1) {
        this.setError(
          `${this.$t("enterAtLeast")} ${this.currency}. 10 ${this.$t(
            "orAbove"
          )}`
        );
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer,
      };
      vm.loading = "loading";
      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = "Please enter amount you want to deposit";
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 428
            ) {
              this.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              this.setError(err.response.data.message);
            }
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
          }
        });
    },

    showDeposit: function () {
      document.getElementById("deposit-init").click();
    },
    searchVisible: function () {
      // console.log("this.current_page ==> " + this.current_page);

      var searchVisible = this.current_page !== "search";
      // console.log(
      //   "this.current_page ==> " +
      //     this.current_page +
      //     " searchVisible ==> " +
      //     searchVisible
      // );

      return searchVisible;
    },
    getTopHeaderHeight: function () {
      const height = this.$refs.topHeader?.clientHeight;
      document.documentElement.style.setProperty(
        "--top-header-height",
        (height ?? 0) + "px"
      );
    },
  },

  beforeDestroy: function () {
    // console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>
<style scoped>
.popular-box {
  display: flex;
  justify-content: space-between;
}

.bets-menu .active {
  color: var(--text-color);
  border-bottom: solid var(--red) 1px;
}
.bets-menu a {
  color: var(--text-color);
  border-bottom: solid var(--red) 1px;
}
.feature-icon-item-wrapper .text-dark {
  padding: 2px 4px;
  color: var(--text-color) !important;
}
.feature-icon-item-wrapper.active .text-dark {
  background: var(--background-color);
  color: var(--text-color);
  border-radius: 240px;
  padding: 2px 4px;
}
.feature-icon-item-wrapper.active .text-dark span {
  color: #cc1717 !important;
}
.feature-icon-item-wrapper.active .txt {
  font-weight: 700 !important;
  color: #cc1717 !important;
}
.icon-container,
.crash-icon-container {
  /* background-color: #f7d9d9; */
  width: 48px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #f5f5f5;
  background: var(--sub-text-color);
}
.icon-container img,
.crash-icon-container img {
  /* background-color: #f7d9d9; */
  width: 24px;
  height: 24px;
}
.crash-icon-container {
  /* background-color: #f7d9d9; */
  /* width: 60px;
  height: 60px; */
  /* display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center; */
}

.header {
  padding: 0.5rem 20px;
}

.top-header {
  position: sticky;
}

.flatlink-div {
  padding: 12px 20px;
}

.live-icon {
  width: 30px !important;
  height: 24px;
  /* img{
    width: 40px;
    
  } */
}
.icon-container,
.crash-icon-container {
  /* background-color: #f7d9d9; */
  width: 48px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border);
  background: var(--background-color);
  color: var(--text-color);
}
.icon-container img,
.crash-icon-container img {
  /* background-color: #f7d9d9; */
  width: 24px;
  height: 24px;
}
.crash-icon-container {
  /* background-color: #f7d9d9; */
  /* width: 60px;
  height: 60px; */
  /* display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center; */
}

.header {
  padding: 0.5rem 20px;
}

.logo {
  display: flex;
  flex-grow: 1;
  gap: 5px;
  align-items: center;
}
.logo img {
  margin-top: -3px;
}

.top-header {
  position: sticky;
}

.side-menu-header {
  display: flex;
  background-color: var(--background-color);
  padding: 8px 20px;
  height: 50px;
  width: 100%;
  align-items: center;
  gap: 8px;
}
.cancel {
  padding: 0;
  margin-top: -3px;
}

.flatlink-div {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid var(--border);
  background-color: var(--background-color);
  color: var(--text-color);
  & > a {
    padding: 12px 20px;
    padding: 0 !important;
    color: var(--text-color);
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  span {
    color: var(--text-color);
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}
.header_text {
  display: flex;
  /* width: 375px; */
  width: 100%;
  height: 32px;
  padding: 12px 20px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--border);
  background: var(--background-color);
  color: var(--text-color);
  span {
    color: var(--text-color);
    font-family: "Satoshi";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
.nav_right {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  gap: 4px;
}
.wallet-balance {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--box-bg);
  color: var(--text-color);
  gap: 4px;
  padding: 0px 4px;
}

.wallet-balance span {
  color: var(--text-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1px;
}
</style>
